import get from 'lodash/get';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import {
  UPDATE_MEAL_FILTER,
  DASHBOARD_CLEAR_ALL_FILTERS,
  CLEAR_MEAL_FILTER,
} from 'actions/mealFilters';
import {
  VEGETARIAN,
  CALORIE_CONSCIOUS,
  CARB_CONSCIOUS,
  GLUTEN_SMART,
  KETO_FRIENDLY,
  PALEO_FRIENDLY,
  HIGH_PROTEIN,
  FIBER_RICH,
  PESCATARIAN,
  NOT_SPICY,
  MILD,
  MEDIUM,
  SPICY,
  DESSERT,
  SIDES,
  MEDITERRANEAN,
  EASY_PREP,
} from 'lib/constants';

import * as fromDashboard from './dashboard';
import * as fromSendABox from './sendABox';

export const FIVE_STAR_RATING = 5;

export const COOK_TIME_FILTER = 'cookTimeFilter';
export const MEAL_TYPE_FILTER = 'mealTypeFilter';
export const DIET_PREF_FILTER = 'dietaryPreferenceFilter';
export const AVOIDANCE_FILTER = 'avoidanceFilter';
export const SPICE_LEVEL_FILTER = 'spiceLevelFilter';
export const PRIMARY_PROTEIN_FILTER = 'primaryProteinFilter';
export const MEAL_BADGE_FILTER = 'mealBadgeFilter';
export const MY_FAVORITES_FILTER = 'myFavoritesFilter';

export const mapFilterNames = {
  [COOK_TIME_FILTER]: 'Cook Time',
  [MEAL_TYPE_FILTER]: 'Meal Type',
  [DIET_PREF_FILTER]: 'Dietary Preferences',
  [AVOIDANCE_FILTER]: 'Dislikes',
  [SPICE_LEVEL_FILTER]: 'Spice Level',
  [PRIMARY_PROTEIN_FILTER]: 'Protein',
  [MEAL_BADGE_FILTER]: 'Featured Meals',
  [MY_FAVORITES_FILTER]: 'Favorites',
};
export const filterToDisplayName = {
  [CALORIE_CONSCIOUS]: 'Calorie-Conscious',
  [CARB_CONSCIOUS]: 'Carb-Conscious',
  [VEGETARIAN]: 'Vegetarian',
  [GLUTEN_SMART]: 'Gluten-Smart',
  [KETO_FRIENDLY]: 'Keto-Friendly',
  [PALEO_FRIENDLY]: 'Paleo-Friendly',
  [HIGH_PROTEIN]: 'Protein-Packed',
  [FIBER_RICH]: 'Fiber-Rich',
  [PESCATARIAN]: 'Pescatarian',
  [EASY_PREP]: 'Easy Prep',
  [MEDITERRANEAN]: 'Mediterranean',
  beef: 'Beef',
  fish: 'Fish',
  milk: 'Milk',
  mushrooms: 'Mushrooms',
  peanuts: 'Peanuts',
  pork: 'Pork',
  poultry: 'Poultry',
  sesame: 'Sesame',
  shellfish: 'Shellfish',
  soy: 'Soy',
  'tree nuts': 'Tree Nuts',
  wheat: 'Wheat',
  [NOT_SPICY]: NOT_SPICY,
  [MILD]: MILD,
  [MEDIUM]: MEDIUM,
  [SPICY]: SPICY,
};

export const initialState = {
  [COOK_TIME_FILTER]: {},
  [MEAL_TYPE_FILTER]: {},
  [DIET_PREF_FILTER]: {},
  [AVOIDANCE_FILTER]: {},
  [SPICE_LEVEL_FILTER]: {},
  [PRIMARY_PROTEIN_FILTER]: {},
  [MEAL_BADGE_FILTER]: {},
  [MY_FAVORITES_FILTER]: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MEAL_FILTER:
      return {
        ...state,
        [action.filter]: action.value,
      };
    case CLEAR_MEAL_FILTER:
      return {
        ...state,
        [action.filter]: initialState[action.filter],
      };
    case DASHBOARD_CLEAR_ALL_FILTERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const filterIsApplied = filter => !isEmpty(filter);

export const selectMealFilters = state => state.mealFilters;

export const selectTotalFiltersApplied = state => {
  const mealFiltersState = selectMealFilters(state);
  let filterTotal = 0;
  Object.values(mealFiltersState).forEach(filterValue => {
    if (isObject(filterValue)) {
      filterTotal += Object.keys(filterValue).length;
    } else if (!isNull(filterValue)) {
      filterTotal += 1;
    }
  });
  return filterTotal;
};

export const selectFilterLabel = (state, filter) => {
  const mealFiltersState = selectMealFilters(state);
  const selectedFilter = mealFiltersState[filter];
  if (isObject(selectedFilter)) {
    const filters = Object.keys(selectedFilter);
    if (filters.length === 1) {
      const filterValue = filters[0];
      return (
        (filter === COOK_TIME_FILTER && `${filterValue} Min`) ||
        filterToDisplayName[filterValue] ||
        filterValue
      );
    } else if (filters.length > 1) {
      return `${mapFilterNames[filter]} · ${filters.length}`;
    }
  }
  return mapFilterNames[filter];
};

export const selectMealFilter = (state, filter) => get(selectMealFilters(state), filter);

export const selectFilteredMealsForBasket = createSelector(
  fromDashboard.selectNonDonationMealsForMenu,
  fromDashboard.selectMealsForBasket,
  selectMealFilters,
  state => state.user,
  (meals, selectedMeals, filters, user) => {
    let [matches, notMatches] = partition(meals, meal =>
      checkMealFilters(meal, filters, user?.favorites, user?.reviews)
    );

    const selectedMealIds = selectedMeals.map(selectedMeal => selectedMeal.id);

    const sortMealsbySelected = meal => {
      return !selectedMealIds.includes(meal.id);
    };

    matches = sortBy(matches, sortMealsbySelected);
    notMatches = sortBy(notMatches, sortMealsbySelected);
    return { matches, notMatches };
  }
);

export const selectFilteredMealsForSendABox = createSelector(
  fromSendABox.selectMealsForMenu,
  selectMealFilters,
  state => state.user,
  (meals, filters, user) => {
    let [matches, notMatches] = partition(meals, meal =>
      checkMealFilters(meal, filters, user?.favorites, user?.reviews)
    );

    return { matches, notMatches };
  }
);

const checkCookTimeFilter = (meal, filter) => {
  const cookTimeMap = {
    '<5': 4,
    '60+': 61,
  };

  const maxCookTime = Number(cookTimeMap[meal.maxCookTime] || meal.maxCookTime);

  if (isNaN(maxCookTime)) return false;

  const filterValue = Number(Object.keys(filter)[0]);

  return maxCookTime <= filterValue;
};

const checkMealTypeFilter = (meal, filter) => {
  const { menuCategoryForMeal, primaryLabel, primaryLabelData } = meal;
  const { labelText } = primaryLabelData || {};

  if (has(filter, SIDES)) {
    return menuCategoryForMeal === SIDES.toLowerCase();
  }

  if (has(filter, DESSERT) && meal.subtitle == 'dessert duo') {
    return filter[DESSERT] === true;
  }

  return filter[primaryLabel || labelText] === true;
};

const checkFilter = (meal, filter, filterName, favorites, reviews) => {
  switch (filterName) {
    case COOK_TIME_FILTER:
      return checkCookTimeFilter(meal, filter);
    case MEAL_TYPE_FILTER:
      return checkMealTypeFilter(meal, filter);
    case PRIMARY_PROTEIN_FILTER:
      return intersection(meal.primaryProteinNames, Object.keys(filter)).length > 0;
    case DIET_PREF_FILTER:
      // Make casing consistent with toLowerCase because the filterableTags are capitalized (stems
      // from dietary preference model FRIENDLY_NAMES), but the quick filters here use lowercase
      return (
        intersection(
          meal.filterableTags?.map(t => t.toLowerCase()),
          Object.keys(filter)?.map(t => t.toLowerCase())
        ).length > 0
      );
    case AVOIDANCE_FILTER:
      return intersection(meal.avoidances, Object.keys(filter)).length === 0;
    case SPICE_LEVEL_FILTER:
      return filter[meal.spiceLevel] === true;
    case MEAL_BADGE_FILTER:
      return meal.mealBadge && filter[meal.mealBadge.name] === true;
    case MY_FAVORITES_FILTER:
      return (
        Object.keys(favorites).includes(meal.mealConcept) ||
        !!reviews
          ?.filter(review => review.recipeRating === FIVE_STAR_RATING)
          ?.find(review => review.mealConcept === meal.mealConcept)
      );
    default:
      return false;
  }
};

export const checkMealFilters = (meal, filters, favorites = {}, reviews = []) => {
  let mealIsInFilterGroup = true;

  [
    COOK_TIME_FILTER,
    MEAL_TYPE_FILTER,
    PRIMARY_PROTEIN_FILTER,
    DIET_PREF_FILTER,
    AVOIDANCE_FILTER,
    SPICE_LEVEL_FILTER,
    MEAL_BADGE_FILTER,
    MY_FAVORITES_FILTER,
  ].forEach(filterName => {
    if (mealIsInFilterGroup === true) {
      if (filters[filterName] && !isEmpty(filters[filterName])) {
        mealIsInFilterGroup = checkFilter(
          meal,
          filters[filterName],
          filterName,
          favorites,
          reviews
        );
      }
    }
  });

  return mealIsInFilterGroup;
};
