import { getFavorites } from 'actions/favorites';
import { getNsoBox } from 'actions/sendABox';
import { getBoxWeeklyBasket } from 'actions/sendABox/boxWeeklyBaskets';
import { getNsoMenuCategories } from 'actions/sendABox/menuCategories';
import { getNsoUpcomingMenus, getNsoMenu } from 'actions/sendABox/menus';
import { getNsoPendingReviews } from 'actions/sendABox/reviews';
import * as fromSendABox from 'reducers/sendABox';

export const BOOTSTRAP_SEND_A_BOX_DASHBOARD = 'BOOTSTRAP_SEND_A_BOX_DASHBOARD';
export const BOOTSTRAP_SEND_A_BOX_DASHBOARD_SUCCESS = 'BOOTSTRAP_SEND_A_BOX_DASHBOARD_SUCCESS';
export const BOOTSTRAP_SEND_A_BOX_DASHBOARD_ERROR = 'BOOTSTRAP_SEND_A_BOX_DASHBOARD_ERROR';

export const bootstrapSendABox =
  (refresh = false) =>
  async (dispatch, getState) => {
    const state = getState();

    if (!refresh && !fromSendABox.needsInitializing(state)) {
      return;
    }

    dispatch({ type: BOOTSTRAP_SEND_A_BOX_DASHBOARD });
    try {
      dispatch({ type: BOOTSTRAP_SEND_A_BOX_DASHBOARD_SUCCESS });
    } catch {
      dispatch({ type: BOOTSTRAP_SEND_A_BOX_DASHBOARD_ERROR });
    }
  };

export const bootstrapUpcomingMenus = mealPlan => async dispatch => {
  await dispatch(bootstrapSendABox());
  await dispatch(getNsoUpcomingMenus({ mealPlan }));
  await dispatch(bootstrapInitialMenus(mealPlan));
};

const bootstrapInitialMenus = mealPlan => async (dispatch, getState) => {
  const state = getState();
  const menuSlugs = fromSendABox.selectInitialMenuSlugs(state);
  return await dispatch(batchFetchMenus(menuSlugs, mealPlan));
};

const batchFetchMenus = (menuSlugs, mealPlan, refresh = false) => {
  return dispatch => {
    return Promise.all(
      menuSlugs.map(menuSlug => dispatch(fetchCompleteMenu(menuSlug, mealPlan, refresh)))
    );
  };
};

export const fetchCompleteMenu = (menuSlug, mealPlan, refresh = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const shouldFetchMenu =
      refresh || fromSendABox.menuNeedsInitialization(state, menuSlug, mealPlan);
    const promises = [shouldFetchMenu && dispatch(getNsoMenu(menuSlug, mealPlan))].filter(Boolean);
    return Promise.all(promises);
  };
};

export const bootstrapSavedEditBox =
  (boxId, shouldGetFavorites = false, fromRecipient = false) =>
  async (dispatch, getState) => {
    let menuSlug, mealPlan;

    const boxExists = fromSendABox.selectBoxes(getState())[boxId];

    if (!boxExists) {
      await dispatch(getNsoBox(boxId));

      const newBox = fromSendABox.selectBoxes(getState())[boxId];
      menuSlug = newBox.menuSlug;
      mealPlan = newBox.mealPlan;
    } else {
      menuSlug = boxExists.menuSlug;
      mealPlan = boxExists.mealPlan;
    }

    if (fromRecipient) {
      await dispatch(getBoxWeeklyBasket(boxId));
    }

    await dispatch(fetchCompleteMenu(menuSlug, mealPlan));
    await dispatch(getNsoMenuCategories(menuSlug, mealPlan));
    if (shouldGetFavorites) await dispatch(getFavorites());
  };

export const fetchReviewsForDeliveredBoxBasket = boxHashId => dispatch => {
  dispatch(getNsoPendingReviews({ boxHashId }));
};
